// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background-image: url("#{$header-image}");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 95vh;
  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;
    .intro-lead-in {
      font-size: 22px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 25px;
      color: #fed136;
      font-family: "Kaushan Script", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    .intro-heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      @include heading-font;
    }
  }
}
@media (min-width: 768px) {
  header.masthead {
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
      .intro-lead-in {
        font-size: 2.5vw;
        font-style: italic;
        line-height: 3.0vw;
        margin-bottom: 25px;
        color: #fed136;
        font-family: "Kaushan Script", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      }
      .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 50px;
        @include heading-font;
      }
    }
  }
}


.team-member {
  margin-bottom: 50px;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  background-color: #fff;
  padding: 20px;
  width: 350px;
  min-height:400px;
  border: 7px solid fade-out($black, 0.9);

  .profile-img {
    width: 225px;
    height: 225px;
    border: 7px solid fade-out($black, 0.9);
  }
  .affil-img{
    margin-top: 10px;
    // margin-bottom: 10px;
    max-width: 248px;
    max-height: 150px;
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin-top: 0;
  }
  .bio-text{
    padding-top: 10px;
  }
}


// Styling for the ack section
.banner {
  margin-bottom: 50px;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  padding-right: 2vw;
  padding-left: 2vw;
  background-color: rgba(255, 255, 255, 0.26);
  padding: 20px;
  width: 30vw;
  border: 7px solid fade-out($black, 0.9);

  .profile-img {
    width: 225px;
    height: 225px;
    border: 7px solid fade-out($black, 0.9);
  }
  .affil-img{
    margin-top: 10px;
    // margin-bottom: 10px;
    max-width: 248px;
    max-height: 150px;
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin-top: 0;
  }
  .bio-text{
    padding-top: 10px;
  }
}
