// Styling for the team section
.team-member {
  margin-bottom: 50px;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  background-color: #fff;
  padding: 20px;
  width: 350px;
  min-height:400px;
  border: 7px solid fade-out($black, 0.9);

  .profile-img {
    width: 225px;
    height: 225px;
    border: 7px solid fade-out($black, 0.9);
  }
  .affil-img{
    margin-top: 10px;
    // margin-bottom: 10px;
    max-width: 248px;
    max-height: 150px;
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin-top: 0;
  }
  .bio-text{
    padding-top: 10px;
  }
}


// Styling for the ack section
.team-ack {
  margin-bottom: 50px;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  background-color: #fff;
  padding: 20px;
  width: 350px;
  border: 7px solid fade-out($black, 0.9);

  .profile-img {
    width: 225px;
    height: 225px;
    border: 7px solid fade-out($black, 0.9);
  }
  .affil-img{
    margin-top: 10px;
    // margin-bottom: 10px;
    max-width: 248px;
    max-height: 150px;
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin-top: 0;
  }
  .bio-text{
    padding-top: 10px;
  }
}
