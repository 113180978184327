// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

p {
  line-height: 1.75;
  font-size:1vw;
}

a {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}

.page-section {
  padding: 100px 0;
  h2.section-heading {
    font-size: 2vw;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 1.5vw;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include serif-font;
  }
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}
@media screen and (max-width: 1200px) {
  p {font-size:14px;}
  .page-section {
    h2.section-heading {
      font-size: 2.0rem;
    }
    h3.section-subheading {
      font-size: 16px;
    }
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}


.team{
  text-align: center;
}
.event-speaker{
  color: #fed136;
  font-family: "Kaushan Script", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.col-centered{
  float: none;
  margin: 0 auto;
}

.fa-user{
  color:white;
}
.fa-globe{
  color:white;
}